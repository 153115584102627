<template>
  <v-card :dark="$dark.get()">
    <loader v-if="loading" />
    <div class="custom-card">
      <div class="custom-card__title header">
        <p>Список поставщиков</p>
        <div class="custom-card__title_btns">
          <v-btn color="success" @click="redirectToLimits(0)">
            Лимиты Аяна
          </v-btn>
          <v-btn color="primary" @click="modal = true">
            Добавить
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </div>
      <div class="custom-card__container">
        <div class="custom-table">
          <div class="head">
            <div
              v-for="item in items.head"
              :key="item.id"
              class="head__item"
              :class="item.id === 1 && 'name'"
            >
              {{ item.name }}
            </div>
          </div>
          <div v-for="item in items.body" :key="item.id" class="value">
            <div class="value__item_name">
              {{ item.name }}
            </div>
            <div class="value__item">
              <v-btn icon color="primary" @click="redirectToProfile(item.id)">
                <v-icon>mdi-arrow-right-circle</v-icon>
              </v-btn>
            </div>
            <div class="value__item">
              <v-btn icon color="primary" @click="redirectToProducts(item.id)">
                <v-icon dark>mdi-arrow-right-circle</v-icon>
              </v-btn>
            </div>
            <div class="value__item">
              <v-btn icon color="primary" @click="deleteProvider(item.id)">
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- модалка для создание нового поставщика -->
    <v-dialog v-model="modal" activator="parent" width="50%">
      <v-card class="modal" :dark="$dark.get()">
        <v-card-title> Создание поставщика </v-card-title>
        <v-form ref="form" @submit.prevent="addNewProvider()">
          <v-text-field
            v-model.trim="newProvider.username"
            :rules="[$validate.required]"
            label="Имя поставщика"
            required
            clearable
          ></v-text-field>
          <v-text-field v-model="newProvider.email" label="Почта">
          </v-text-field>
          <v-text-field
            v-model="newProvider.password"
            label="Пароль"
            type="password"
            :rules="[$validate.required, $validate.passMin]"
          >
          </v-text-field>
          <v-select
            v-model="newProvider.departmentIds"
            label="Филиал"
            :items="USER.userInfo.departments"
            item-text="name"
            item-value="id"
            :rules="[$validate.arrRequired]"
            multiple
          >
          </v-select>
          <v-card-actions class="flex justify-end">
            <v-btn right @click="modal = false">Отмена</v-btn>
            <v-btn color="primary" type="submit" right>Добавить</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <delete-dialog
      :show="show"
      @close-modal="show = !show"
      @delete-modal="deleteProvider()"
    />
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import loader from "@/components/Loader";
import DeleteDialog from "@/components/DeleteDialog";
import showMessage from "@/Functions/message";

export default {
  components: {
    loader,
    DeleteDialog,
  },
  data() {
    return {
      loading: false,
      modal: false,
      isRedacting: false,
      show: false,
      deletedName: "",
      newProvider: {
        username: "",
        departmentIds: [],
        password: "",
        email: "",
        roleId: 5,
      },
      items: {
        head: [
          { id: 1, name: "Имя поставщика" },
          { id: 4, name: "Профиль" },
          { id: 2, name: "Товары" },
          { id: 9, name: "Удалить" },
        ],
        body: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      STATE: "Providers/STATE",
      USER: "User/STATE",
    }),
  },
  watch: {
    // сбрасывает валидацию при закрытии окна
    modal(newVal) {
      if (!newVal) {
        this.newProvider.username = "";
        this.newProvider.password = "";
        this.newProvider.email = "";
        this.newProvider.departmentIds = [];
        this.$refs.form.resetValidation();
      }
    },
  },
  mounted() {
    if (this.STATE.allProviders.length === 0) {
      this.setValues();
    } else {
      this.items.body = this.STATE.allProviders;
    }
  },
  methods: {
    ...mapActions({
      getAllProviders: "Providers/GET_ALL_PROVIDERS",
      getProviderProducts: "Providers/GET_PROVIDER_PRODUCTS",
      deleteProviderAction: "Providers/DELETE_PROVIDER",
      addProvider: "Providers/ADD_PROVIDER",
      addUser: "User/ADD_USER",
    }),
    //Подгружает всех поставщиков
    async setValues() {
      this.loading = true;
      await this.getAllProviders();
      if (this.STATE.allProviders.length > 0) {
        this.items.body = this.STATE.allProviders;
      } else {
        showMessage("Данные не загрузились");
      }
      this.loading = false;
    },
    //Добавляет нового поставщика
    async addNewProvider() {
      if (!this.$refs.form.validate()) {
        showMessage("Заполните все поля в форме");
        return;
      }
      this.loading = true;
      // сначала создается пользователь, а затем поставщик
      const responseUser = await this.addUser(this.newProvider);
      if (responseUser.type === "error") {
        showMessage(responseUser.text);
        this.loading = false;
        return;
      }
      const request = {
        name: responseUser.data.username,
        userId: responseUser.data.id,
      };
      const responseProvider = await this.addProvider(request);
      if (responseProvider.type === "error") {
        showMessage(response.data.message);
      } else {
        showMessage("Поставщик создан", true);
        await this.setValues();
        this.modal = false;
      }
      this.loading = false;
    },
    async deleteProvider(name) {
      if (name) {
        this.deletedName = name;
      } else {
        this.loading = true;
        let response = await this.deleteProviderAction(this.deletedName);
        if (response?.type === "error") {
          showMessage(response.data.message);
        } else {
          showMessage("Поставщик удален", true);
          await this.setValues();
        }
        this.deletedName = 0;
        this.loading = false;
      }
      this.show = !this.show;
    },
    redirectToProducts(id) {
      this.$router.push("providers-list/" + id);
    },
    redirectToLimits(id) {
      this.$router.push("providers-limit/" + id);
    },
    redirectToProfile(id) {
      this.$router.push("providers-profile/" + id);
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-card {
  padding: 40px;
  position: relative;
  &__title {
    margin-bottom: 30px;
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    &_btns {
      display: flex;
      gap: 10px;
    }
  }
  .custom-card__container {
    .custom-table {
      .head {
        display: flex;
        justify-content: space-between;
        &__item {
          text-align: left;
          font-weight: 500;
          width: 12%;
          text-align: center;
        }
      }
      .name {
        width: 40%;
        text-align: left;
      }
      .value {
        display: flex;
        justify-content: space-between;
        &__item {
          margin-top: 20px;
          width: 12%;
          display: flex;
          align-items: center;
          justify-content: center;
          &_name {
            width: 40%;
            display: flex;
            align-items: center;
            justify-content: left;
          }
        }
        &__btn {
          width: 100%;
        }
      }
    }
  }
}
.modal {
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &__text {
    padding: 0;
    margin: 0;
  }
}
.checkboxes {
  display: flex;
  gap: 20px;
}
</style>
